import { BOARD_USER_INFO, BOARD_DATA, BOARD_SEARCHNAME, BOARD_ADMIN_INFO,
    QUESTION_INSERT, QUESTION_DATA, QUESTION_DETAILDATA,
    NOTICE_DATA, NOTICE_DETAILDATA, NOTICE_INSERT, NOTICE_SELECTPAGE, NOTICE_STATE,
    ADMIN_QUESTIONDATA, FAQ_DATA, FAQ_INSERT,
    DOWNLOAD_DATA,
    MY_QUESTION_CURRENT_PAGE, ADMIN_QUESTION_CURRENT_PAGE,
    USER_NOTICE_CURRENT_PAGE, ADMIN_NOTICE_CURRENT_PAGE,
    USER_FAQ_CURRENT_PAGE} from '../action/actionTypes'

export default function(state = {}, action) {
    switch (action.type) {
        case QUESTION_DATA:
            return {
                ...state,
                question_Data: action.questionData
            }
            break;
        case QUESTION_DETAILDATA:
            return {
                ...state,
                question_DetailData: action.questionDetailData
            }
            break;
        case BOARD_SEARCHNAME:
            return {
                ...state,
                board_SearchName: action.board_SearchName
            }
            break;
        case BOARD_DATA:
            return {
                ...state,
                select_Data : action.select_BoardData
            }
            break;
        case BOARD_USER_INFO:
            return {
                ...state,
                boardUserInfo: action.board_UserInfo
            }
            break;
        case NOTICE_DATA:
            return {
                ...state,
                notice_Data: action.noticeData
            }
            break;
        case NOTICE_DETAILDATA:
            return {
                ...state,
                notice_DetailData: action.noticeDetailData
            }
            break;
        case NOTICE_SELECTPAGE:
            return {
                ...state,
                notice_SelectPage: action.noticeSelectPage
            }
            break;
        case QUESTION_INSERT:
            return {
                ...state,
                question_Result: action.questionResult
            }
            break;
        case NOTICE_INSERT:
            return {
                ...state,
                notice_Result: action.noticeResult
            }
            break;
        case NOTICE_STATE:
            return {
                ...state,
                notice_State: action.noticeState
            }
            break;
        case DOWNLOAD_DATA:
            return {
                ...state,
                download_Data: action.downloadData
            }
            break;
        case ADMIN_QUESTIONDATA:
            return {
                ...state,
                adminQuestionData: action.admin_QuestionData
            }
            break
        case FAQ_DATA:
            return {
                ...state,
                faq_data: action.faqData
            }
            break
        case BOARD_ADMIN_INFO:
            return {
                ...state,
                boardAdminInfo: action.board_AdminInfo
            }
            break
        case FAQ_INSERT:
            return {
                ...state,
                faqResult: action.faq_Result
            }
            break
        case MY_QUESTION_CURRENT_PAGE:
            return {
                ...state,
                myQuestionCurrentPage: action.myQuestionCurrentPage
            }
            break
        case USER_NOTICE_CURRENT_PAGE:
            return {
                ...state,
                userNoticeCurrentPage: action.userNoticeCurrentPage
            }
        default:
            return state
            break;
    }
}