import {SETTING_ALERTDIALOGOBJECT, SETTING_EVENTALARMOBJECT, SETTING_EVENTVIDEOOBJECT} from '../action/actionTypes'

export default function(state = {}, action){
  switch (action.type){
    case SETTING_ALERTDIALOGOBJECT:
      return{
        ...state,
        Setting_AlertDialogObject : {...action.Setting_AlertDialogObject}
      }
    case SETTING_EVENTALARMOBJECT:
      return{
        ...state,
        Setting_EventAlarmObject : {...action.Setting_EventAlarmObject}
      }
    case SETTING_EVENTVIDEOOBJECT:
      return{
        ...state,
        Setting_EventVideoObject : {...action.Setting_EventVideoObject}
      }
    default:
      return state
  }
}
