import page_header from './page_header.json'
import page_home from './page_home.json'
import page_intro from './page_intro.json'
import page_data from './page_data.json'
import page_market from './page_market.json'
import page_safezone from './page_safezone.json'
import page_common from './page_common.json'

export default{
    page_header,
    page_home,
    page_intro,
    page_data,
    page_market,
    page_safezone,
    page_common
}