import React from 'react'

import 'jquery-ui-bundle'
// import '../../css/bootstrap.min.css'
// import '../../css/common.css'

const Loading = (props) => {
  const check = props.loading == null ? false : props.loading


  return (
    check &&
    <div className="loading_wrap">
      <div className="spinner"></div>
    </div>
  )

}

export default Loading
