import {COMMON_SAVE_CONTEXTPATH, COMMON_LANG_TYPE} from "../action/actionTypes";


export default function (state = {}, action) {
  switch (action.type) {
    case COMMON_SAVE_CONTEXTPATH:
      return {
        ...state,
        Common_SaveContextPath: action.Common_SaveContextPath
      }
      break;
    case COMMON_LANG_TYPE:
      return {
        ...state,
        Common_Lang_Type: action.Common_Lang_Type
      }
      break;
    default:
      return state
    break;
  }
}
