import {LOGIN_MEMBER, LOG_OUT, ROLE} from '../action/actionTypes'






export default function(state = {}, action){
  switch (action.type){
    case LOGIN_MEMBER:
      return{
        ...state,
        memberId: action.memberId
      }
      break;
    case LOG_OUT:
      return {...state,
        login: 'false'
      }
      break;
    default:
      return state
      break;
  }
}
