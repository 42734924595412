import {combineReducers} from "redux";
import auth from './AuthReducer'
import menu from './MenuReducer'
import sidebarShow from'./SidebarReducer'
import setting from'./SettingReducer'
import alert from'./AlertReducer'
import board from'./BoardReducer'




import common from './CommonReducer'




const rootReducer = combineReducers({
  auth,
  menu,
  sidebarShow,
  setting,
  alert,
  board,
  common,
})

export default rootReducer
