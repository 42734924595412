import React from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogLabel,
  AlertDialogOverlay
} from "@reach/alert-dialog";

//다국어
import { useTranslation } from 'react-i18next'

const AlertDialogComponent = (props) => {
  const { t, i18n } = useTranslation(['page_common']);
  let message = typeof props.description == "string"? props.description.split('\\n')
  : props.description? props.description[0].split('\\n') : '';
  let isForce = props.isForce;

  return (  
    <AlertDialogOverlay className="dialog_backdrop" data-no-focus-lock leastDestructiveRef={props.cancelRef}>
      <AlertDialogContent className="dialog_content">
        {/* <button className="close" onClick={()=>props.close(isForce? isForce : false)} /> */}
        <AlertDialogLabel className="dialog_tit"></AlertDialogLabel>
        <AlertDialogDescription className="dialog_description">
          {
            message &&
              message.map((txt, index) => {
                return(
                  <p key={index}>
                    {txt}
                  </p>
                )
              })
          }
        </AlertDialogDescription>
        <div className="dialog_btn_area">
          <button className="btn-skyblue" ref={props.cancelRef} onClick={()=>props.close(true)}>{t('확인')}</button>
        </div>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};

export default AlertDialogComponent;
