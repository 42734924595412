import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'

import {applyMiddleware, createStore} from "redux"
import promiseMiddleware from 'redux-promise'
import ReduxThunk from 'redux-thunk'
import Reducer from './modules/reducer'
import {CookiesProvider} from 'react-cookie'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './utiles/i18n'

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore)

ReactDOM.render(
  // <Provider store={store}>
  <CookiesProvider>
    <Provider store={createStoreWithMiddleware(Reducer,
          window.__REDUX_DEVTOOLS_EXTENSION__&&
                      window.__REDUX_DEVTOOLS_EXTENSION__()
                    )}
    >
      <App />
    </Provider>
  </CookiesProvider>
  ,

  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
