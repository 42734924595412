import axios from "axios";
import { LOGIN_MEMBER , FIND_ID, FIND_PWD } from './actionTypes'

export async function actionTerms(data) {
  let result

  await axios.get("/api/auth/terms")
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionUpdateTerms(data) {
  let result
  let queryString = "memberId=" + data.memberId

  await axios.get("/api/auth/updateTerms?" + queryString)
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionCheckId(data) {
  let result
  let queryString = "memberId=" + data.memberId

  await axios.get("/api/auth/checkId?" + queryString)
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionSignUp(data) {
  let result

  await axios.post("/api/auth/sign-up", data)
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionLoginMember(data) {
  let result
  await axios.post("/api/auth/login", data)
    .then(res => {
      result = res.data
    })
  return {
    type: LOGIN_MEMBER,
    payload: result
  }
}

export async function actionPwdChange(data) {
  let result

  await axios.post("/api/auth/pwdChange", data)
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionCreateAuthCode(data) {
  let result

  await axios.post("/api/auth/auth-code", data)
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionGetAuthCode(data) {
  let result

  await axios.get(`/api/auth/auth-code`, {params: data})
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionGetFindId(data) {
  let result

  await axios.get(`/api/auth/id`, {params: data})
    .then(res => {
      result = res.data
    })

  return result
}

export async function actionPutFindPw(data) {
  let result

  await axios.put(`/api/auth/password`, data)
    .then(res => {
      result = res.data
    })

  return result
}

