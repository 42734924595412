import React, { Component, useEffect, useState, useRef } from 'react'
import { HashRouter, Route, Redirect, Switch, BrowserRouter, Router, useHistory } from 'react-router-dom'
import Loading from "./views/components/Loading";
import AlertDialogComponent from "./views/components/AlertDialogComponent";
import { hot } from 'react-hot-loader'


import 'jquery-ui-bundle'
// import 'jquery-ui-bundle/jquery-ui.min.css'
// import './assets/css/font.css'
import './assets/css/reset.css'
import "@reach/dialog/styles.css";
import './assets/css/swiper.css'
import "react-datepicker/dist/react-datepicker.css";
// import 'bootstrap/dist/js/bootstrap.bundle'; /* can't use */
import { getCookie, setCookie, removeCookie } from "./utiles/cookie";
import axios from 'axios';
import { browserName, browserVersion, isIE, isChrome, isMobileOnly, deviceDetect, useDeviceSelectors } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
//toast
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

import {
	LOGIN_MEMBER,
	COMMON_SAVE_CONTEXTPATH,
	SETTING_ALERTDIALOGOBJECT,
	SETTING_LOADING,
	SETTING_EVENTALARMOBJECT,
	SETTING_EVENTVIDEOOBJECT,
	MENU_SELECTMENU,
	SETTING_SCREENSHOTIMAGE,
	COMMON_BPLC_VISIBLE
} from './modules/action/actionTypes';
import { getRole } from "./modules/action/AuthAction";

import {
	BrowserView,
	MobileView,
	TabletView,
	isTablet,
	isBrowser,
	isMobile,
} from "react-device-detect";
import ScrollTop from './views/components/ScrollTop';
import { useTranslation } from 'react-i18next'

// Main Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const TabletLayout = React.lazy(() => import('./layout/tablet/TabletLayout'))
const MobileLayout = React.lazy(() => import('./layout/mobile/MobileLayout'))
// Intro Page
const Blank = React.lazy(() => import('./views/returnPage/Blank'))

//MyQuestionPage
// const MyQuestionPage = React.lazy(() => import('./views/pages/board/MyQuestionPage'))

function App() {
	const dispatch = useDispatch()
	const ua = navigator.userAgent;
	const isLinux = ua.includes('Linux');
	const { t, i18n } = useTranslation(['page_common']);

	/*************** alert ***************/
	const cancelRef = React.useRef();
	const [showAlertDialog, setShowAlertDialog] = useState(false)
	const [alertDialogObject, setAlertDialogObject] = useState({ description: '', close: null, isClose: false })

	const funcAlertMsg = (msg, action, isForce) => {
		setAlertDialogObject({
			description: [msg],
			isForce: isForce,
			close: (isConfirm) => {
				if (action && isConfirm) action();
				alertDialogClose();
			}
		})
		setShowAlertDialog(true)
	}
	const alertDialogClose = () => {
		setShowAlertDialog(false)
	}
	/*************** alert ***************/
	//toast
	const toastSuccess = (msg) => {
		toast.success(msg, { position: toast.POSITION.BOTTOM_CENTER })
	}

	const [loading, setLoading] = useState(true)

	let token = getCookie('codipai');

	//전체 로딩 spinner
	let state_Loading = useSelector(state => state.setting.Setting_Loading)
	let contextPath = ""
	if (sessionStorage.getItem("contextpath") != null && sessionStorage.getItem("contextpath") != "null") {
		contextPath = sessionStorage.getItem("contextpath")
	}
	const [role, setRole] = useState('')
	// let userRole = ''

	useEffect(() => {
		dispatch({ type: SETTING_LOADING, Setting_Loading: false })
		dispatch({ type: SETTING_EVENTALARMOBJECT, Setting_Loading: false })
		dispatch({ type: SETTING_EVENTVIDEOOBJECT, Setting_Loading: false })
		dispatch({ type: COMMON_SAVE_CONTEXTPATH, Common_SaveContextPath: contextPath })
	}, [])

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			// 운영서버 배포 빌드 날짜를 로컬스토리지에 세팅
			fetch("/meta.json")
				.then((response) => response.json())
				.then((meta) => {
					window.localStorage.setItem('buildDate', meta.buildDate)
				});
		}

		//axios 호출시 인터셉트
		axios.interceptors.request.use(function (config) {
			token = getCookie('codipai');
			config.headers.common['Authorization'] = `Bearer ${token}`;

			config.baseURL = contextPath
			return config
		}, function (error) {
			return Promise.reject(error);
		});

		//axios 호출 종료시 인터셉트
		axios.interceptors.response.use(function (response) {
			dispatch({ type: SETTING_LOADING, Setting_Loading: false })
			return response;
		}, function (error) {
			dispatch({ type: SETTING_LOADING, Setting_Loading: false })
			if (error.response.status == "401") {
				removeCookie('codipai');
				sessionStorage.clear();
				setAlertDialogObject({
					// description: [error.response.data.message],
					description: [t('로그인 정보가 만료 되었습니다.'), t('다시 로그인 해주세요.')],
					close: () => window.location.replace("/auth/login")
				})
			}
			else {
				setAlertDialogObject({
					// description: [error.response.data.message],
					description: error.response.data.message,
					close: () => alertDialogClose(error.response.data)
				})
			}

			setShowAlertDialog(true)
			return Promise.reject(error);
		});
	}, []);

	return (
		<>
			<BrowserView className='pc-view'>
				<BrowserRouter>
					<ScrollTop />
					<React.Suspense fallback={<Loading loading={loading} />}>
						{isIE == true ? <Redirect to="/returnPage" /> : ''}
						<Switch>
							{
								<Route path="/" name="Home" render={(props) => {
									if (isLinux) {
										return <TabletLayout {...props} funcAlertMsg={funcAlertMsg} toastSuccess={toastSuccess} />
									} else {
										return <DefaultLayout {...props} funcAlertMsg={funcAlertMsg} toastSuccess={toastSuccess} />
									}
								}}
								/>
							}
						</Switch>
					</React.Suspense>
				</BrowserRouter>
			</BrowserView>
			{
				isTablet ?
					<TabletView className='tablet-view'>
						<BrowserRouter>
							<ScrollTop />
							<React.Suspense fallback={<Loading loading={loading} />}>
								{isIE == true ? <Redirect to="/returnPage" /> : ''}
								<Switch>
									{
										<Route path="/" name="Home" render={(props) => {
											return <TabletLayout {...props} funcAlertMsg={funcAlertMsg} toastSuccess={toastSuccess} />
										}}
										/>
									}
								</Switch>
							</React.Suspense>
						</BrowserRouter>
					</TabletView>
					:
					<MobileView className='mobile-view'>
						<BrowserRouter>
							<ScrollTop />
							<React.Suspense fallback={<Loading loading={loading} />}>
								{isIE == true ? <Redirect to="/returnPage" /> : ''}
								<Switch>
									{
										<Route path="/" name="Home" render={(props) => {
											return <MobileLayout {...props} funcAlertMsg={funcAlertMsg} toastSuccess={toastSuccess} />
										}}
										/>
									}
								</Switch>
							</React.Suspense>
						</BrowserRouter>
					</MobileView>
			}

			<Loading loading={state_Loading} />
			{/* <CommonAlertDialogComponent show={state_AlertDialogObject}/> */}
			{
				showAlertDialog && <AlertDialogComponent cancelRef={cancelRef} description={alertDialogObject.description} close={alertDialogObject.close} isForce={alertDialogObject.isForce} />
			}
		</>
	)

}

export default hot(module)(App)
