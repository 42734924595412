import {
  SETTING_CAMERAANALYSISSAVE,
  SETTING_CAMERAINFO,
  SETTING_CAMERAINFOSAVE,
  SETTING_CAMERALIST, SETTING_CAMERASETTINGMODALOPEN,
  SETTING_CCTVURL, SETTING_CHANGECAMERAINFO, SETTING_ISDRAWING, SETTING_LOADING,
  SETTING_MODALCLOSE, SETTING_OBJECTINFOSAVE, SETTING_ORDERTYPE, SETTING_SAVEDATA,
  SETTING_SCREENSHOTIMAGE,
  SETTING_SCREENSHOTMODALOPEN,
  SETTING_SCREENSHOTSAVE,
  SETTING_SCREENSHOTSAVEIMAGE,
  SETTING_SETTINGCLASS, SETTING_SPACEINFOSAVE,
  SETTING_SPACELINEOBJECT,
  SETTING_SPACEPOLYOBJECT
} from "../action/actionTypes";


export default function (state = {Setting_ScreenShotSave: false}, action) {
  switch (action.type) {
    //카메라 설정 모달 꺼짐유무 (rtsp 소켓통신 끊기)
    case SETTING_MODALCLOSE:
      return {
        ...state,
        Setting_ModalClose: action.Setting_ModalClose
      }
      break;
    case SETTING_SCREENSHOTMODALOPEN:
      return {
        ...state,
        Setting_ScreenShotModalOpen: action.Setting_ScreenShotModalOpen
      }
      break;
    case SETTING_CCTVURL:
      return {
        ...state,
        Setting_CctvUrl: action.Setting_CctvUrl
      }
      break;
    case SETTING_SCREENSHOTIMAGE:
      return {
        ...state,
        Setting_ScreenShotImage: action.Setting_ScreenShotImage
      }
      break;
    case SETTING_SCREENSHOTSAVEIMAGE:
      return {
        ...state,
        Setting_ScreenShotSaveImage: action.Setting_ScreenShotSaveImage
      }
      break;
    case SETTING_CAMERAINFO:
      return {
        ...state,
        Setting_CameraInfo: action.Setting_CameraInfo
      }
      break;
    case SETTING_SETTINGCLASS:
      return {
        ...state,
        Setting_SettingClass: action.Setting_SettingClass
      }
      break;
    case SETTING_CAMERAINFOSAVE:
      return {
        ...state,
        Setting_CameraInfoSave: action.Setting_CameraInfoSave
      }
      break;
    case SETTING_CAMERALIST:
      return {
        ...state,
        Setting_CameraList: action.Setting_CameraList
      }
      break;
    case SETTING_SPACEPOLYOBJECT:
      return {
        ...state,
        Setting_SpacePolyObject: action.Setting_SpacePolyObject
      }
      break;
    case SETTING_SPACELINEOBJECT:
      return {
        ...state,
        Setting_SpaceLineObject: action.Setting_SpaceLineObject
      }
      break;
    case SETTING_SPACEINFOSAVE:
      return {
        ...state,
        Setting_SpaceInfoSave: action.Setting_SpaceInfoSave
      }
      break;
    case SETTING_OBJECTINFOSAVE:
      return {
        ...state,
        Setting_ObjectInfoSave: action.Setting_ObjectInfoSave
      }
      break;
    case SETTING_CAMERASETTINGMODALOPEN:
      return {
        ...state,
        Setting_CameraSettingModalOpen: action.Setting_CameraSettingModalOpen
      }
      break;
    case SETTING_LOADING:
      return {
        ...state,
        Setting_Loading: action.Setting_Loading
      }
      break;
    case SETTING_ORDERTYPE:
      return {
        ...state,
        Setting_OrderType: action.Setting_OrderType
      }
      break;
    case SETTING_ISDRAWING:
      return {
        ...state,
        Setting_IsDrawing: action.Setting_IsDrawing
      }
      break;
    case SETTING_SAVEDATA:
      return {
        ...state,
        Setting_SaveData: action.Setting_SaveData
      }
      break;
    case SETTING_CAMERAANALYSISSAVE:
      return {
        ...state,
        Setting_CameraAnalysisSave: action.Setting_CameraAnalysisSave
      }
      break;
    case SETTING_CHANGECAMERAINFO:
      return {
        ...state,
        Setting_ChangeCameraInfo: action.Setting_ChangeCameraInfo
      }
      break;
    default:
      return state
      break;
  }
}
