import { MENU_SELECTMENU, MENU_SETMENUAUTH } from '../action/actionTypes'

export default function (state = {}, action) {

  switch (action.type) {

    case MENU_SETMENUAUTH:
      return {
        ...state,
        menu_menuAuth: action.menu_menuAuth
      }
      break;

    case MENU_SELECTMENU:
      return {
        ...state,
        menu_selectMenu: action.menu_selectMenu
      }
      break;

    default:
      return state
      break;

  }

}