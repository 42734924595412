import {SELECTMENU, SIDENAVBAR_ONOFF} from "../action/actionTypes";

export default function (state = {sidebarShow: true}, action) {
  switch (action.type) {
    case SIDENAVBAR_ONOFF:
      return {
        ...state,
        sideNavBarOnOff: action.sideNavBarOnOff
      }
      break;

    default:
      return state
      break;
  }
}
