//로그인
export const LOGIN_MEMBER = "LOGIN_MEMBER"
export const LOG_OUT = "LOG_OUT"
export const ROLE = "ROLE"
export const SIDENAVBAR_ONOFF = "SIDENAVBAR_ONOFF"
export const MENU_SELECTMENU = "MENU_SELECTMENU"
export const SETTING_LOADING = "SETTING_LOADING"
export const SETTING_ALERTDIALOGOBJECT = "SETTING_ALERTDIALOGOBJECT"
export const SETTING_EVENTALARMOBJECT = "SETTING_EVENTALARMOBJECT"
export const SETTING_EVENTVIDEOOBJECT = "SETTING_EVENTVIDEOOBJECT"
export const FIND_ID = "FIND_ID"
export const SUCCESS_FIND_ID = "SUCCESS_FIND_ID"
export const FIND_PWD = "FIND_PWD"


//menu
export const MENU_SETMENUAUTH = "MENU_SETMENUAUTH"


//common
export const COMMON_SAVE_CONTEXTPATH = "COMMON_SAVE_CONTEXTPATH"
export const COMMON_LANG_TYPE = "COMMON_LANG_TYPE"  //언어 타입
export const COMMON_BPLC_VISIBLE = "COMMON_BPLC_VISIBLE"


//tumor-data
export const DATA_IMAGEMODALTOGGLE = "DATA_IMAGEMODALTOGGLE"









//3d
export const SETTING_MODALCLOSE = "SETTING_MODALCLOSE"
export const SETTING_SCREENSHOTMODALOPEN = "SETTING_SCREENSHOTMODALOPEN"
export const SETTING_CCTVURL = "SETTING_CCTVURL"
export const SETTING_CAMERAINFO = "SETTING_CAMERAINFO"
export const SETTING_SCREENSHOTIMAGE = "SETTING_SCREENSHOTIMAGE"
export const SETTING_SCREENSHOTSAVEIMAGE = "SETTING_SCREENSHOTSAVEIMAGE"
export const SETTING_SETTINGCLASS = "SETTING_SETTINGCLASS"
export const SETTING_CAMERAINFOSAVE = "SETTING_CAMERAINFOSAVE"
export const SETTING_CHANGECAMERAINFO = "SETTING_CHANGECAMERAINFO"
export const SETTING_CAMERALIST = "SETTING_CAMERALIST"
export const SETTING_SPACEPOLYOBJECT = "SETTING_SPACEPOLYOBJECT"
export const SETTING_SPACELINEOBJECT = "SETTING_SPACELINEOBJECT"
export const SETTING_SELECTCAMERA = "SETTING_SELECTCAMERA"
export const SETTING_SPACEINFOSAVE = "SETTING_SPACEINFOSAVE"
export const SETTING_OBJECTINFOSAVE = "SETTING_OBJECTINFOSAVE"
export const SETTING_CAMERASETTINGMODALOPEN = "SETTING_CAMERASETTINGMODALOPEN"
export const SETTING_ORDERTYPE = "SETTING_ORDERTYPE"
export const SETTING_ISDRAWING = "SETTING_ISDRAWING"
export const SETTING_SAVEDATA = "SETTING_SAVEDATA"
export const SETTING_CAMERAANALYSISSAVE = "SETTING_CAMERAANALYSISSAVE"

//PassWord Change
export const PASSWORD_CHANGE = "PASSWOED_CHANGE"

//문의
export const BOARD_USER_INFO = "BOARD_USER_INFO"
export const BOARD_ADMIN_INFO = "BOARD_ADMIN_INFO"
export const BOARD_SEARCHNAME = "BOARD_SEARCHNAME"
export const BOARD_SELECTPAGE = "BOARD_SELECTPAGE"
export const BOARD_DATA = "BOARD_DATA"
export const QUESTION_DATA = "QUESTION_DATA"
export const ADMIN_QUESTIONDATA = "ADMIN_QUESTIONDATA"
export const QUESTION_DETAILDATA = "QUESTION_DETAILDATA"
export const QUESTION_INSERT = "QUESTION_INSERT"
export const QUESTION_UPDATE = "QUESTION_UPDATE"
export const QUESTION_SELECTPAGE = "QUESTION_SELECTPAGE"
export const QUESTIOn_SELECTGROUP = "QUESTIOn_SELECTGROUP"
export const QUESTION_SEARCHRESULT = "QUESTION_SEARCHRESULT"
export const FAQ_INIT = "FAQ_INIT"
export const FAQ_SELECTGROUP = "FAQ_SELECTGROUP"
export const FAQ_SELECTPAGE = "FAQ_SELECTPAGE"
export const FAQ_DATA = "FAQ_DATA"
export const FAQ_INSERT = "FAQ_INSERT"
export const NOTICE_DATA = "NOTICE_DATA"
export const ADMIN_NOTICEDATA = "ADMIN_NOTICEDATA"
export const NOTICE_DETAILDATA = "NOTICE_DETAILDATA"
export const NOTICE_INSERT = "NOTICE_INSERT"
export const NOTICE_STATE = "NOTICE_STATE"
export const ATTACH_FILENO = "ATTACH_FILENO"
export const DOWNLOAD_DATA = "DOWNLOAD_DATA"
export const NOTICE_SELECTGROUP = "FAQ_SELECTGROUP"
export const NOTICE_SELECTPAGE = "FAQ_SELECTPAGE"
export const MY_QUESTION_CURRENT_PAGE = "MY_QUESTION_CURRENT_PAGE"
export const ADMIN_QUESTION_CURRENT_PAGE = "ADMIN_QUESTION_CURRENT_PAGE"
export const USER_NOTICE_CURRENT_PAGE = "USER_NOTICE_CURRENT_PAGE"
export const ADMIN_NOTICE_CURRENT_PAGE = "ADMIN_NOTICE_CURRENT_PAGE"
export const USER_FAQ_CURRENT_PAGE = "USER_FAQ_CURRENT_PAGE"



//내정보
export const MYINFO_MEMBERINFO = "MYINFO_MEMBERINFO"
export const MYINFO_CHECKPWD = "MYINFO_CHECKPWD"
export const MYINFO_GETPROFILEIMAGE = "MYINFO_GETPROFILEIMAGE"
export const MYINFO_NEWIMAGEPATH = "MYINFO_NEWIMAGEPATH"    //프로필 이미지 src
export const MYINFO_UPDATEIMAGEYN = "MYINFO_UPDATEIMAGEYN"
export const MYINFO_IMAGEMODALTOGGLE = "MYINFO_IMAGEMODALTOGGLE"
export const MYINFO_PWDMODALTOGGLE = "MYINFO_PWDMODALTOGGLE"
export const MYINFO_DOUBLECHECKEMAIL = "MYINFO_DOUBLECHECKEMAIL"
export const MYINFO_CHANGEPWD = "MYINFO_CHANGEPWD"
export const MYINFO_NEWIMAGEFILE = "MYINFO_NEWIMAGEFILE"
export const MYINFO_BASEIMAGEYN = "MYINFO_BASEIMAGEYN"
export const MYINFO_QNANOTICECNT = "MYINFO_QNANOTICECNT"
export const MYINFO_EVENTCNT = "MYINFO_EVENTCNT"
export const MYINFO_MEMBERCNT = "MYINFO_MEMBERCNT"
export const MYINFO_PAGEPATH = "MYINFO_PAGEPATH"
export const MYINFO_PROFILESTATUS = "MYINFO_PROFILESTATUS"  // 0: 기본이미지 , 1: s3파일 , 2: 변경된 파일 src
export const MYINFO_CAMERALIST = "MYINFO_CAMERALIST"
export const MYINFO_PAGETYPE = "MYINFO_PAGETYPE"    // 카메라 모달 수정 버튼 권한
export const MYINFO_CHARGECOUNT = "MYINFO_CHARGECOUNT"  //내 담당 카메라 이벤트 당일
export const MYINFO_BPLCLIST = "MYINFO_BPLCLIST"    //내가 소속된 사업장 리스트








